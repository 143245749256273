<template lang="pug">
div

  v-system-bar(app fixed)
    v-btn(icon @click="addItem"): v-icon mdi-plus
    v-btn(icon @click="clearCart"): v-icon mdi-trash-can
    v-checkbox(v-model="formEmpty" label="Empty")
    v-checkbox(v-model="formDisabled" label="Disabled")
    v-chip {{left}}
    v-chip {{clicked}}
    v-chip {{right}}

  t-list(title="General")
    v-list-item.mb-2
      Basket(@click="clicked++" @right="right++")
    v-list-item.mb-2
      Basket(@click="clicked++" no-icon @left="left++")
    v-list-item.mb-2
      Basket(@click="clicked++" no-icon no-menu @left="left++")

  t-list(title="Left Button")
    v-list-item.mb-2
      Basket(@click="clicked++" no-icon @left="left++" emptyDisabled emptyLabel="Empty Card")
        template(slot="left-content" slot-scope="{isEmpty,disabled}")
          v-btn(color="red" x-large :disabled="disabled" :outlined="isEmpty" depressed @click="clearCart(); left++"): v-icon mdi-trash-can
    v-list-item.mb-2
      Basket(@click="addItem(); clicked++" no-menu @left="left++" :empty="formEmpty" :disabled="formDisabled") Добавить в чек
    v-list-item.mb-2
      Basket(@click="addItem(); clicked++" no-menu @left="left++" :empty="formEmpty" :disabled="formDisabled")
        div Добавить в чек
        .font-weight-regular Итого 2000

  t-list(title="Right Button (Reversed)")
    v-list-item.mb-2
      Basket(@click="clicked++" no-icon @left="left++" emptyDisabled emptyLabel="Empty Card")
        template(slot="right-content" slot-scope="{isEmpty,disabled}")
          v-btn(color="red" x-large :disabled="disabled" :outlined="isEmpty" depressed @click="clearCart(); right++"): v-icon mdi-trash-can
    v-list-item.mb-2
      Basket(@click="addItem(); clicked++" no-menu reverse @right="right++" :empty="formEmpty" :disabled="formDisabled") Добавить в чек
    v-list-item.mb-2
      Basket(@click="addItem(); clicked++" no-menu reverse @right="right++" :empty="formEmpty" :disabled="formDisabled")
        div Добавить в чек
        .font-weight-regular Итого 2000

  t-list(title="Free Text")
    v-list-item.mb-2
      Basket(@click="clicked++" no-icon no-menu) X-ОТЧЕТ
    v-list-item.mb-2
      Basket(@click="clicked++" @right="right++" no-icon) Y-ОТЧЕТ
    v-list-item.mb-2
      Basket(@click="clicked++" @right="right++" no-icon no-actions :total="1000") Z-ОТЧЕТ

  t-list(title="TBtn")
    v-list-item.mb-2
      t-btn(@click="clicked++" no-menu) X-ОТЧЕТ
    v-list-item.mb-2
      t-btn(@click="clicked++" @right="right++") Y-ОТЧЕТ
    v-list-item.mb-2
      t-btn(@click="clicked++" @right="right++" no-actions :total="1000") Z-ОТЧЕТ

  t-list(title="General")
    t-list-item(title="Bottom Menu Icon")
      v-switch(color="teal")
    t-list-item(title="Hide Home")
      v-switch(color="teal")
    t-list-item(title="Theme") Material Rally

</template>
<script>

import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Basket: () => import('T@/components/cart/Basket.vue'),
  },
  computed: {
    ...mapState({
      totalSum: 'tjtheme/total',
    }),
  },
  data: () => ({
    left: 0,
    clicked: 0,
    right: 0,
    formEmpty: true,
    formDisabled: true,
  }),
  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
      addItem: 'tjtheme/addItem',
    }),
  },

}
</script>
<style lang="stylus"></style>
